import type { NuxtApp } from 'nuxt/app'
import { RxPrescriptionOverlays } from '../useOverlay'
import type {
  IOption,
  ISightPrescriptionStepInput,
  IValidatorResponse,
  ISightPrescriptionStep,
} from '~/components/rx/SightPrescription/SightPrescriptionStep.d.ts'

type InputOption = {
  min: number
  max: number
  step: number
}

type InputValue = number | undefined

const PRESCRIPTION_OPTION_VALUES: Record<IPrescriptionProp, InputOption> = {
  sphere: {
    min: -6,
    max: 6,
    step: 0.25,
  },
  cylinder: {
    min: -4,
    max: 4,
    step: 0.25,
  },
  axis: {
    min: 0,
    max: 180,
    step: 1,
  },
  pd: {
    min: 25,
    max: 40,
    step: 0.5,
  },
}

const PRESCRIPTION_CROSS_VALIDATION: Partial<
  Record<IPrescriptionProp, (...args: any) => boolean>
> = {
  axis: (state: any, currentAxis: any, eye: any) => {
    const {
      cylinder: { left, right },
    } = state
    const cylinderValue = eye === 'L' ? left : right
    const currentAxisIsDefined = currentAxis !== undefined && currentAxis !== 0
    if (cylinderValue && cylinderValue !== 0) {
      return currentAxis !== undefined
    } else if (cylinderValue === undefined && currentAxisIsDefined) {
      return false
    } else if (
      !cylinderValue &&
      cylinderValue !== 0 &&
      (currentAxis === 0 || currentAxis)
    ) {
      return false
    }
    return true
  },
}

/**
 * Generate values for select fields
 * @param options min/max/step values
 * @returns generated values
 */
const generateValues = (
  prescriptionProp: IPrescriptionProp,
  currentShop: NuxtApp['$currentShop'],
): IOption[] => {
  const { min, max, step } = PRESCRIPTION_OPTION_VALUES[prescriptionProp]
  const count = Math.floor((max - min) / step) + 1
  return Array.from(Array(count)).map((_, index) => {
    const value: number = min + index * step
    return {
      value,
      label: formatPrescriptionValue(
        prescriptionProp,
        value,
        currentShop,
        false,
      ),
    }
  })
}

const diff = (a: InputValue, b: InputValue, _state: IPrescriptionValues) =>
  Math.abs((a || 0) - (b || 0))

const signMismatch = (
  a: InputValue,
  b: InputValue,
  _state: IPrescriptionValues,
) => ((a || 0) > 0 && (b || 0) < 0) || ((a || 0) < 0 && (b || 0) > 0)

export const cylinderMustBeSet = (
  leftAxis: InputValue,
  rightAxis: InputValue,
  state: IPrescriptionValues,
) => {
  return (
    (state.cylinder.left === undefined && leftAxis !== undefined) ||
    (state.cylinder.right === undefined && rightAxis !== undefined)
  )
}

const isValid = (
  value: number | undefined,
  options: ISightPrescriptionStepInput,
  state: IPrescriptionValues,
  eye: 'L' | 'R',
  canBeUndefined: boolean = false,
) => {
  const { min = -Infinity, max = Infinity, step = 0, crossValidation } = options
  const areDependenciesValid = crossValidation
    ? crossValidation(state, value, eye)
    : true

  if (canBeUndefined) {
    return areDependenciesValid
  } else if (typeof value !== 'undefined') {
    return (
      value >= min &&
      value <= max &&
      (!step || value % step === 0) &&
      areDependenciesValid
    )
  }

  return false
}

const rules = [
  {
    group: 'cylinder',
    message: 'rx.prescription.rules.warningSignMismatch.cylinder',
    hasWarning: (...values: [InputValue, InputValue, IPrescriptionValues]) =>
      signMismatch(...values),
  },
  {
    group: 'sphere',
    message: 'rx.prescription.rules.warningBoth.sphere',
    hasWarning: (...values: [InputValue, InputValue, IPrescriptionValues]) =>
      signMismatch(...values) && diff(...values) > 4,
  },
  {
    group: 'sphere',
    message: 'rx.prescription.rules.warningStronglyDiffering.sphere',
    hasWarning: (...values: [InputValue, InputValue, IPrescriptionValues]) =>
      diff(...values) > 4,
  },
  {
    group: 'sphere',
    message: 'rx.prescription.rules.warningSignMismatch.sphere',
    hasWarning: (...values: [InputValue, InputValue, IPrescriptionValues]) =>
      signMismatch(...values),
  },
  {
    group: 'axis',
    message: 'rx.prescription.rules.cylinderMustBeSet',
    hasWarning: (...values: [InputValue, InputValue, IPrescriptionValues]) =>
      cylinderMustBeSet(...values),
  },
]

export const getValidator =
  (step: IPrescriptionProp) =>
  (
    state: IPrescriptionValues,
    left?: number,
    right?: number,
  ): IValidatorResponse => {
    const response: IValidatorResponse = {
      isLeftValid: undefined,
      isRightValid: undefined,
      warningText: '',
    }
    const inputOptions = PRESCRIPTION_OPTION_VALUES[step]
    const crossValidation = PRESCRIPTION_CROSS_VALIDATION[step]
    const options = { ...inputOptions, crossValidation }

    const isAxisOrCylinder = ['cylinder', 'axis'].includes(step)
    const isLeftToValidate = left !== undefined || isAxisOrCylinder
    const isRightToValidate = right !== undefined || isAxisOrCylinder

    // Validate values
    if (isLeftToValidate) {
      response.isLeftValid = isValid(
        left,
        options,
        state,
        'L',
        isAxisOrCylinder,
      )
    }
    if (isRightToValidate) {
      response.isRightValid = isValid(
        right,
        options,
        state,
        'R',
        isAxisOrCylinder,
      )
    }

    // Check rules if we should show a warning
    if (
      (isLeftToValidate && isRightToValidate) ||
      response.isLeftValid === false ||
      response.isRightValid === false
    ) {
      response.warningText =
        rules.find(
          (rule) => rule.group === step && rule.hasWarning(left, right, state),
        )?.message || ''
    }

    return response
  }

const getPrescriptionSteps = (
  currentShop: NuxtApp['$currentShop'],
): ISightPrescriptionStep[] => [
  {
    name: 'sphere',
    unit: 'dpt',
    storyblokSlug: RxPrescriptionOverlays.SPHERE,
    options: generateValues('sphere', currentShop),
    validate: getValidator('sphere'),
    step: 1,
    isOptional: false,
  },
  {
    name: 'cylinder',
    unit: 'dpt',
    storyblokSlug: RxPrescriptionOverlays.CYLINDER,
    options: generateValues('cylinder', currentShop),
    validate: getValidator('cylinder'),
    step: 2,
    isOptional: true,
  },
  {
    name: 'axis',
    unit: '°',
    storyblokSlug: RxPrescriptionOverlays.AXIS,
    options: generateValues('axis', currentShop),
    validate: getValidator('axis'),
    step: 3,
    isOptional: true,
  },
  {
    name: 'pd',
    unit: 'mm',
    storyblokSlug: RxPrescriptionOverlays.PD,
    options: generateValues('pd', currentShop),
    validate: getValidator('pd'),
    step: 4,
    isOptional: false,
  },
]

export default getPrescriptionSteps
