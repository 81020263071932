import { useSessionStorage } from '@vueuse/core'
import { getValidator } from '~/composables/rxConfig/PrescriptionFormConfig'

export const EMPTY_PRESCRIPTION_VALUE: IPrescriptionValue = {
  left: undefined,
  right: undefined,
}

export const DEFAULT_PRESCRIPTION_VALUE: IPrescriptionValue = {
  left: 0,
  right: 0,
}

const initValues: IPrescriptionValues = {
  sphere: { ...EMPTY_PRESCRIPTION_VALUE },
  cylinder: { ...EMPTY_PRESCRIPTION_VALUE },
  axis: { ...EMPTY_PRESCRIPTION_VALUE },
  pd: { ...EMPTY_PRESCRIPTION_VALUE },
}

export const useRxPrescription = () => {
  const prescriptionValues = useSessionStorage<IPrescriptionValues>(
    'prescriptionValues',
    initValues,
  )

  const getPrescriptionPropSetters = (prescriptionProp: IPrescriptionProp) => {
    const toNumber = (val: string | number | undefined): number | undefined => {
      if (typeof val === 'number') {
        return val
      }

      const result = parseFloat(`${val}`)
      return isNaN(result) ? undefined : result
    }
    const setLeft = (left: number | string | undefined) => {
      prescriptionValues.value[prescriptionProp].left = toNumber(left)
    }
    const setRight = (right: number | string | undefined) => {
      prescriptionValues.value[prescriptionProp].right = toNumber(right)
    }
    return { setLeft, setRight }
  }

  const setPrescriptionValues = (values: IPrescriptionValues) => {
    Object.assign(prescriptionValues.value, values)
  }

  const resetPrescriptionValues = () => {
    prescriptionValues.value = {
      sphere: { ...initValues.sphere },
      cylinder: { ...initValues.cylinder },
      axis: { ...initValues.axis },
      pd: { ...initValues.pd },
    }
  }

  const isPrescriptionValid = (supportsPrescriptionValues: boolean) => {
    if (!supportsPrescriptionValues) {
      return true
    }
    const values = Object.entries(prescriptionValues.value)

    return (
      values.every(([key, value]) => {
        const { left, right } = (initValues as any)[key]
        return (
          value.left === left &&
          value.right === right &&
          right !== EMPTY_PRESCRIPTION_VALUE.right &&
          left !== EMPTY_PRESCRIPTION_VALUE.left
        )
      }) ||
      values.every(([key, value]) => {
        const prescriptionProp = key as IPrescriptionProp
        const currentStepValidator = getValidator(prescriptionProp)
        const validationResponse = currentStepValidator(
          prescriptionValues.value,
          value.left,
          value.right,
        )
        return (
          validationResponse?.isLeftValid && validationResponse?.isRightValid
        )
      })
    )
  }

  return {
    prescriptionValues,
    getPrescriptionPropSetters,
    setPrescriptionValues,
    resetPrescriptionValues,
    isPrescriptionValid,
  }
}
